<template>
	<div class="wrap">
		<el-page-header @back="$router.go(-1)" content="客户列表"/>
		<div class="topBox">
			<el-form class="forms" style="display: flex;"  ref="form" :model="form" label-width="80px">
				<el-form-item label="咨询时间">
					 <el-date-picker
					      @change="change1" v-model="form.create_time"
					      type="datetime"
					      placeholder="选择日期时间">
					</el-date-picker>
				  </el-form-item>
				<el-form-item label="状态">
					<el-select v-model="form.status" placeholder="请选择">
						<el-option label="未联系" value="0"></el-option> <!-- 0是未联系 默认是0 -->
						<el-option label="已联系" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button  icon="el-icon-refresh" @click="handleAllRefresh"></el-button>
					<el-button type="primary" @click="determine">提交</el-button>
					<el-button type="primary" @click="clear">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content">
			<el-table class="tables" :data="newList" ref="productTable"  stripe
				@selection-change="handleSelect">
				<el-table-column type="selection"></el-table-column>
				<el-table-column label="任务id" width="100">
					<template slot-scope="scope">
						{{task_id}}
					</template>
				</el-table-column>
				<el-table-column label="任务名" width="100">
					<template slot-scope="scope">
						{{scope.row.task_name}}
					</template>
				</el-table-column>
				<el-table-column label="视频" width="240">
					<template slot-scope="scope">
						{{scope.row.title}}
					</template>
				</el-table-column>
				<el-table-column label="D音昵称" width="100">
					<template slot-scope="scope">
						{{scope.row.nickname}}
					</template>
				</el-table-column>
				<el-table-column label="D音号" width="110">
					<template slot-scope="scope">
						{{scope.row.short_id}}
					</template>
				</el-table-column>
				<el-table-column label="uid" width="100">
					<template slot-scope="scope">
						{{scope.row.uid}}
					</template>
				</el-table-column>
				<el-table-column label="线索内容" width="200">
					<template slot-scope="scope">
						{{scope.row.text}}
					</template>
				</el-table-column>
				<el-table-column label="命中关键词" width="100">
					<template slot-scope="scope">
						{{scope.row.hit_keyword}}
					</template>
				</el-table-column>
				<el-table-column label="咨询时间" width="180">
					<template slot-scope="scope">
						{{scope.row.create_time}}
					</template>
				</el-table-column>
				<el-table-column label="状态" width="100">
					<template slot-scope="scope">
						<div class="box" v-if="scope.row.status == 1">
							已联系
						</div>
						<div class="box" v-if="scope.row.status == 0">
							<!--默认0 -->
							未联系
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="160">
					<template slot-scope="scope">
						<el-button type="primary" @click="getMa(scope.row)">扫码私信</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="footer">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:page-sizes="[10, 20, 30]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				:current-page.sync="currentPage" :total="total" background>
			</el-pagination>
		</div>
		<el-dialog title="扫码私信" :visible.sync="dialogVisible" style="margin-top: 15vh;" width="20%"
			:before-close="handleClose">
			<div class="imgBox">
				<img :src="erweima">
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				erweima: '',
				dialogVisible: false,
				task_id: '', //任务id
				video_id:'',//从抖音过来得id
				newList: [],
				form: {
					video_id:'',
					task_id:'',
					status: '',
					create_time:'',
					seller_id: this.$store.state.SELLER_ID

				},
				total: 0, //数据总数
				pageSize: 10, //每页显示数量
				currentPage: 1, //当前页数
				p:0 ,  //总页数
			}
		},
		created() {
			if(this.$route.query.task_id && this.$route.query.video_id){
				this.video_id = this.$route.query.video_id;
				this.form.video_id = this.$route.query.video_id;
				this.task_id = this.$route.query.task_id;
				this.form.task_id = this.$route.query.task_id;
			}else{
				this.task_id = this.$route.query.task_id;
				this.form.task_id = this.$route.query.task_id;
			}
			this.getList()
		},
		methods: {
			// 分页器
			handleSizeChange(val) {
				this.pageSize = val
				this.pageNum=val
				this.getList()
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.p =val
				this.getList()
			},
			change1(value) {
				const dates = new Date(value);
				this.form.create_time = dates.getTime()
			},
			//获取抖音二维码
			getMa(row) {
				let integral = Number(sessionStorage.getItem('integral'))
				if(integral > 10){					
					if(row.status == 0){
						this.$confirm('此操作需要消耗十积分, 是否继续?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.geterweima(row.id)
						})
					}else{
						this.geterweima(row.id)
					}
				}else{
					this.$confirm('积分不足,请到个人中心进行积分充值, 是否进行充值?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						window.open(this.$store.state.USER_URL+'/#/jf_recharge',"_blank")
					})
				}
				
			},
			geterweima(id){
				this.$axios.post('/getCustomerCode', {
					seller_id: this.$store.state.SELLER_ID,
					id: id
				}).then(data => {
					if (data.code == "0000") {
						this.dialogVisible = true
						this.erweima = data.path
					}else{
						this.dialogVisible = false
						this.$message.error("获取失败")
					}
				})
			},
			getList() {
				this.$axios.post('/getCustomerList', {
					limit:this.pageSize,
					page:this.currentPage,
					seller_id: this.$store.state.SELLER_ID,
					task_id: this.task_id,
					video_id:this.video_id,
				}).then(data => {
					if(data.count){
						this.total = data.count   // 总条数
					}
					this.newList = data.data
					// this.total = data.count   // 总条数
					// if(data.count){
					// 	this.total = data.count   // 总条数
					// 	this.p =Math.ceil( data.count / pageSize)   //总页数
					// }
					// var start = (currentPage - 1) * pageSize   //开始位置 当前页数 *条数
					// var end = currentPage * pageSize    //结束位置
					// var datas = data.data
					// var c = datas.slice(start,end)
					// this.newList = c
				})
			},
			//提交表单
			determine() {
				let form = this.form
				if(form.create_time == '' && form.status == ''){
					this.$message.error("至少选择一项进行筛选")
				}else{
					this.$axios.post('/getCustomerList', this.form).then(data => {
						this.$message.success("获取成功")
						this.newList = data.data
					})
				}
			},
			//清空
			clear() {
				let form = this.form
				form.create_time = ''
				form.status = ''
			},
			scanCode() {

			},
			//刷新
			handleAllRefresh() {
				this.getList()
			},
			//删除
			handleAllDelete() {

			}
		}
	}
</script>

<style scoped>
	.topBox {
		margin: 0 20px;
		padding-top: 10px;
	}

	.ships {
		font-size: 12px;
	}

	.content {
		padding: 0 28px;
	}

	.wrap {
		width: 1600px;
		padding-bottom: 20px;
		background-color: #fff;
	}

	.wrap>>>.el-table th {
		background: #e3e4e7;
		color: #120500;
	}

	.tableOperate {
		margin: 10px 0px 10px 30px;
	}

	.tableOperate .el-button {
		margin-right: 20px;
	}

	.wrap>>>.el-page-header {
		padding: 20px;
	}

	.forms input {
		width: 216px;
	}

	.imgBox {
		display: flex;
		justify-content: center;
		padding-bottom: 20px;
	}

	.imgBox img {
		width: 200px;
		height: 200px;
	}
	.footer {
		padding: 75px 0 86px;
		text-align: center;
	}
	
	.footer>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #ff4344;
	}
	
	.footer>>>.el-pagination.is-background .el-pager li:not(.disabled):hover {
		color: #C0C4CC;
	}
	.el-table th>.cell{
		text-align: center;
	}
</style>
